var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "오시는 길"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("C")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ontact Us")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xl my-n20px my-md-n30px my-lg-n50px"
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "py-20px py-md-30px py-lg-50px",
      attrs: {
        "cols": "12",
        "md": item.large ? 12 : 6
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm mb-12px mb-md-24px line-height-15",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('v-divider', {
      staticClass: "primary",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }), _c('iframe', {
      staticClass: "d-block",
      staticStyle: {
        "border": "0"
      },
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200",
        "src": item.iframe,
        "width": "100%",
        "height": "400",
        "allowfullscreen": "",
        "loading": "lazy",
        "referrerpolicy": "no-referrer-when-downgrade"
      }
    }), _c('div', {
      staticClass: "mt-20px mt-md-40px"
    }, [_c('v-divider'), _c('u-txt-default', {
      staticClass: "line-height-1"
    }, [_c('div', {
      staticClass: "py-16px py-md-24px"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": item.large ? 6 : 12
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "min-w-40px min-w-md-60px"
    }, [_c('strong', {
      staticClass: "grey--text text--darken-4"
    }, [_vm._v("주소")])])]), _c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_c('p', [_vm._v(_vm._s(item.address))])])], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": item.large ? 6 : 12
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "min-w-40px min-w-md-60px"
    }, [_c('strong', {
      staticClass: "grey--text text--darken-4"
    }, [_vm._v("전화")])])]), _c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_c('p', [_vm._v(_vm._s(item.tel))])])], 1)], 1)], 1)], 1)]), _c('v-divider')], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }