var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-view"
  }, [_c('div', {
    staticClass: "board-view__row board-view__row--title"
  }, [_vm._v(" 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. ")]), _c('div', {
    staticClass: "board-view__row board-view__row--info"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 작성자 : 관리자 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 작성일 : 2022-06-24 10:16:32 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 조회수 : 2949 ")])], 1)], 1), _c('div', {
    staticClass: "board-view__row board-view__row"
  }, [_vm._v(" 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. ")])]), _c('div', {
    staticClass: "mt-24px"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary3', {
    staticClass: "font-weight-medium",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }