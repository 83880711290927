<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="성공사례" :tabActive="category === 'firm' ? '이혼센터 성공사례' : '전체 성공사례'">
                <template #titHead>S</template>
                <template #tit>uccess Story</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <story-view :category="category" @category="(value)=>{ category = value }"/>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import StoryView from "@/components/client/board/skin/story/view.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,

        StoryView,
    },
    data() {
        return {
            category: ""
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
