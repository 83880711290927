var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "언론&미디어",
            "tabActive": "대표변호사칼럼"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("I")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("nsight & "), _c('span', {
                staticClass: "d-inline-block primary--text",
                attrs: {
                  "data-aos": "zoom-out",
                  "data-aos-delay": "1000"
                }
              }, [_vm._v("M")]), _vm._v("edia")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('column-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }