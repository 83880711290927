import { render, staticRenderFns } from "./u-pagination-outlined-rounded.vue?vue&type=template&id=2827c756&scoped=true&"
import script from "./u-pagination-outlined-rounded.vue?vue&type=script&lang=js&"
export * from "./u-pagination-outlined-rounded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2827c756",
  null
  
)

export default component.exports