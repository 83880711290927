<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="리브이혼센터" tabActive="리브이혼센터 소개">
                <template #titHead>R</template>
                <template #tit>e : V</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--about page-section--first page-section--last">
            <div v-for="(item, index) in info" :key="index" :class="index !== 0 ? 'pt-40px pt-md-24px' : ''">
                <v-row justify="center" align="center" class="about-row">
                    <v-col cols="auto" class="about-row__tit"> 
                        <div data-aos="zoom-out" data-aos-delay="400" class="w-120px w-md-200px h-120px h-md-200px rounded-circle border" style="border-color: var(--v-primary-base) !important;">
                            <div class="w-100 h-100 d-flex align-center justify-center text-center">
                                <u-tit-default class="primary--text">
                                    {{item.title}}
                                </u-tit-default>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md=""> 
                        <card-secondary>
                            <div class="pa-16px pa-md-32px">
                                <u-txt-default>
                                    <p data-aos="fade-up" v-for="(detail, index) in item.txt" :key="index" v-html="detail" class="line-height-17 mb-8px mb-md-16px" :class="index !== 0 ? 'mt-8px mt-md-16px' : ''"></p>
                                    <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm font-primary line-height-15">
                                        {{item.txtAdd}}
                                    </u-tit-default>
                                </u-txt-default>
                            </div>
                        </card-secondary>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
    },
    data() {
        return {
            info: [
                {
                    title: "Private",
                    txt: [
                        "리브 이혼상속센터는 의뢰인의 개인정보 및 소중한 삶의 권익을 위하여 최적의 맞춤형 하이퀄리티 법률서비스를 제공합니다.<br class='d-none d-xl-block' /> 사생활 보호가 가장 중요한 이혼사건의 특성을 늘 중점에 두고 사건을 처리합니다. 또한 자체적인 리브만의 프로세스를 적용하여 최초 상담부터 사건이 마무리 되는 끝까지 든든한 동반자로 함께 동행합니다.",
                    ],
                    txtAdd: "사생활 보호가 가장 중요한 이혼사건의 특성을 고려하여 모든 사건을 해결합니다.",
                },
                {
                    title: "Personal",
                    txt: [
                        "리브 이혼상속센터는 이혼,상속,민사,형사 등 각분야에서 전문성을 갖춘 변호사들이 모였습니다.",
                        "100% 똑같은 사건은 존재하지 않습니다. 사건의 내용은 같아보일지라도 깊게 본다면 다 다릅니다. 이에 리브는 대표변호사를 필두로 TF팀을 구성하여 개인의 사건의 본질을 정확하게 파악하여 방향성을 잡고 개인별 맞춤형 진행방향을 제시합니다. 자신이 없는 사람은 남이 만들어놓은 길로 따라가지만, 자신이 있는 사람은 원하는 곳에 길을 만듭니다. 매번 똑같은 사건진행방식이 아닌, 상황별 '새로운 진행방향'이 '새로운 결과'를 불러올 수 있습니다.",
                    ],
                    txtAdd: "여러분의 새로운 삶으로 가는 방향은 리브가 만들겠습니다.",
                },
                {
                    title: "Premium",
                    txt: [
                        "리브이혼상속센터는 VIP를 위해 특화된 센터로, 사건의 결과 뿐 아니라 사건의 진행과정에서도 만족감을 선사합니다.<br class='d-none d-xl-block' /> 모든 사건에는 대표변호사의 법률검토가 함께하며 소통에 오류가 없는 변호사와의 밀착 사건 진행이 이루어 집니다. 리브에는 사무장이 존재하지 않아 모든 소통이 변호사와 단독으로 이루어 지는 Hot Line 시스템으로 이루어 집니다. 소통오류, 불편함. 리브에선 절대 찾아보실 수 없습니다. ",
                    ],
                    txtAdd: "리브만의 High quality Legal Services, 자신이 없으면 자부하지도 않습니다.",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.about-row{
    --line-width: 64px;
    margin: calc(var(--line-width) / -2) 0;
    >[class*="col"]{
        padding: calc(var(--line-width) / 2) 0;
    }
    &__tit{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: var(--line-width);
            height: 4px;
            background-image: url(/images/icon/dot-line.svg);
            background-position: center;
            background-repeat: no-repeat;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(90deg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .about-row{
        --line-width: 104px;
        margin: 0 calc(var(--line-width) / -2);
        >[class*="col"]{
            padding: 0 calc(var(--line-width) / 2);
        }
        &__tit{
            &::after{
                bottom: unset;
                left: unset;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
