<template>
    <div v-if="insight">
        <div class="board-view">
            <div class="board-view__row board-view__row--title">
                {{insight.subject}}
            </div>
            <div class="board-view__row board-view__row--info">
                <v-row>
                    <v-col cols="auto">
                        작성자 : {{insight.writer?.name}}
                    </v-col>
                    <v-col cols="auto">
                        작성일 : {{$dayjs(insight.createdAt).format("YYYY-MM-DD HH:mm:ss")}}
                    </v-col>
                    <v-col cols="auto">
                        조회수 : {{insight.viewCount}}
                    </v-col>
                </v-row>
            </div>
            <div class="board-view__row board-view__row">
               <div v-html="insight.content.replace(/\n/g, '<br>')" />
            </div>
        </div>
        <div class="mt-24px">
            <v-row justify="end">
                <v-col cols="auto">
                    <btn-secondary3 class="font-weight-medium" @click="$router.go(-1)">리스트</btn-secondary3>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import BtnSecondary3 from "@/components/publish/parents/buttons/btn-secondary3.vue";

export default {
    props: {
    },
    components: {
        BtnSecondary3,
    },
    data() {
        return {
            insight: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { insight } = await api.v1.insights.get({ _id: this.$route.params._insight })
                
                this.insight = insight;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>