<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.01 17.99"
        class="icon-svg icon-arrow-long"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M10.83,5.2L5.82,.08v.02c-.22-.16-.53-.13-.73,.07L.17,5.2c-.11,.11-.17,.26-.17,.42s.06,.3,.16,.41c.23,.23,.59,.23,.82,0L4.91,2.02v15.38c0,.32,.26,.59,.58,.59h.02c.32,0,.58-.27,.58-.59V2.01l3.93,4.02c.22,.23,.59,.23,.82,0,.11-.11,.17-.26,.17-.42s-.06-.3-.17-.42h0Z"
        />
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 12"
        class="icon-svg icon-arrow-long"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M5.71041 0.67L0.59041 5.68L0.61041 5.68C0.45041 5.9 0.48041 6.21 0.68041 6.41L5.71041 11.33C5.82041 11.44 5.97041 11.5 6.13041 11.5C6.29041 11.5 6.43041 11.44 6.54041 11.34C6.77041 11.11 6.77041 10.75 6.54041 10.52L2.53041 6.59L17.9104 6.59C18.2304 6.59 18.5004 6.33 18.5004 6.01L18.5004 5.99C18.5004 5.67 18.2304 5.41 17.9104 5.41L2.52041 5.41L6.54041 1.48C6.77041 1.26 6.77041 0.89 6.54041 0.66C6.43041 0.55 6.28041 0.49 6.12041 0.49C5.96041 0.49 5.82041 0.55 5.70041 0.66L5.71041 0.67Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 12px;
            }
            &-x-small {
                width: 14px;
            }
            &-small {
                width: 16px;
            }
            &-default {
                width: 18px;
            }
            &-large {
                width: 20px;
            }
            &-x-large {
                width: 22px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 12px;
            }
            &-x-small {
                height: 14px;
            }
            &-small {
                height: 16px;
            }
            &-default {
                height: 18px;
            }
            &-large {
                height: 20px;
            }
            &-x-large {
                height: 22px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
