<template>
    <div>
        <div class="board-view">
            <div class="board-view__row board-view__row--title">
                게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.
            </div>
            <div class="board-view__row board-view__row--info">
                <v-row>
                    <v-col cols="auto">
                        작성자 : 관리자
                    </v-col>
                    <v-col cols="auto">
                        작성일 : 2022-06-24 10:16:32
                    </v-col>
                    <v-col cols="auto">
                        조회수 : 2949
                    </v-col>
                </v-row>
            </div>
            <div class="board-view__row board-view__row">
                해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다. 해당 게시물의 내용이 옵니다.
            </div>
        </div>
        <div class="mt-24px">
            <v-row justify="end">
                <v-col cols="auto">
                    <btn-secondary3 class="font-weight-medium" @click="$router.go(-1)">리스트</btn-secondary3>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import BtnSecondary3 from "@/components/publish/parents/buttons/btn-secondary3.vue";

export default {
    props: {
    },
    components: {
        BtnSecondary3,
    },
}
</script>

<style lang="scss" scoped></style>