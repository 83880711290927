import { render, staticRenderFns } from "./forms-textarea.vue?vue&type=template&id=d0c561b0&scoped=true&"
import script from "./forms-textarea.vue?vue&type=script&lang=js&"
export * from "./forms-textarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0c561b0",
  null
  
)

export default component.exports