<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="오시는 길">
                <template #titHead>C</template>
                <template #tit>ontact Us</template>
            </sub-head-primary>
        </template>
        <page-section-primary class="page-section--first page-section--last">
            <v-row class="row--xl my-n20px my-md-n30px my-lg-n50px">
                <v-col v-for="item in info" :key="item.title" cols="12" :md="item.large ? 12 : 6" class="py-20px py-md-30px py-lg-50px">
                    <u-tit-default data-aos="fade-up" class="tit--sm mb-12px mb-md-24px line-height-15">{{item.title}}</u-tit-default>
                    <v-divider data-aos="fade-up" data-aos-delay="200" class="primary" />
                    <iframe data-aos="fade-up" data-aos-delay="200" :src="item.iframe" class="d-block" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                    <div class="mt-20px mt-md-40px">
                        <v-divider />
                            <u-txt-default class="line-height-1">
                                <div class="py-16px py-md-24px">
                                    <v-row>
                                        <v-col cols="12" :md="item.large ? 6 : 12">
                                            <v-row no-gutters>
                                                <v-col data-aos="fade-up" cols="auto">
                                                    <div class="min-w-40px min-w-md-60px">
                                                        <strong class="grey--text text--darken-4">주소</strong>
                                                    </div>
                                                </v-col>
                                                <v-col data-aos="fade-up" data-aos-delay="200">
                                                    <p>{{item.address}}</p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" :md="item.large ? 6 : 12">
                                            <v-row no-gutters>
                                                <v-col data-aos="fade-up" cols="auto">
                                                    <div class="min-w-40px min-w-md-60px">
                                                        <strong class="grey--text text--darken-4">전화</strong>
                                                    </div>
                                                </v-col>
                                                <v-col data-aos="fade-up" data-aos-delay="200">
                                                    <p>{{item.tel}}</p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </u-txt-default>
                        <v-divider />
                    </div>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {
            info: [
                {
                    title: "서울 주사무소",
                    address: "서울시 서초구 서초대로 272, 9층 (한국아이비에스 빌딩)",
                    tel: "02-523-0522",
                    iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.6439520141985!2d127.0090070763206!3d37.492727628356135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca16c439226ef%3A0xd26f24c0e19e4152!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDshJzstIjrjIDroZwgMjcy!5e0!3m2!1sko!2skr!4v1703746422535!5m2!1sko!2skr",
                    large: true,
                },
                {
                    title: "수원 분사무소",
                    address: "수원시 영통구 광교중앙로 248번길 95-1, 7층 (광교법조타운)",
                    tel: "031-213-0522",
                    iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.1517930127675!2d127.06578927631351!3d37.29153613984857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5b11bd2701bd%3A0xfe8f00df9aabd7ed!2z6rK96riw64-EIOyImOybkOyLnCDsmIHthrXqtawg6rSR6rWQ7KSR7JWZ66GcMjQ467KI6ri4IDk1LTE!5e0!3m2!1sko!2skr!4v1703747007072!5m2!1sko!2skr",
                },
                {
                    title: "인천 분사무소",
                    address: "인천시 미추홀구 소성로 171, 로시스동 3층 (대흥평창로시스빌딩)",
                    tel: "032-866-0522",
                    iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3167.7967945246482!2d126.6649249763189!3d37.441904931264204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b79a416b78189%3A0x3984c891254cf627!2z7J247LKc6rSR7Jet7IucIOuvuOy2lO2ZgOq1rCDshozshLHroZwgMTcx!5e0!3m2!1sko!2skr!4v1703747027904!5m2!1sko!2skr",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
