<template>
    <div>

        <board-filter data-aos="fade-up" class="mb-40px mb-md-80px"         
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="()=> {page=1; search(true)}"/>

        <v-row data-aos="fade-up" data-aos-delay="200">

            <!-- S: story -->
            <v-col v-for="(success, index) in successes" :key="index" cols="6" md="4" >
                <story-item :to="`/story/${success._id}`" :thumb="success.thumb">
                    {{ success.subject}}
                    <template #date>{{ $dayjs(success.createdAt).format("YYYY-MM-DD") }}</template>
                </story-item>
            </v-col>
            <!-- E: story -->            

        </v-row>

    </div>
</template>

<script>
import api from "@/api"
import { PANSIM_TYPES } from "@/assets/variables"
import BoardFilter from "@/components/client/board/board-filter.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import StoryItem from "@/components/client/board/skin/story/item.vue";

export default {
    props: {
        category: { type: String, default: ""}
    },
    components: {
        BoardFilter,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,

        StoryItem,
    },
    data() {
        return {
            successes: [],
            filter: { 
                code: this.category || this.$route.query.code || null,
                site: PANSIM_TYPES.DIVORCE.value,
                searchKey: 'subject'
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(routable = false){
            if (routable) {
                this.$router.push({
                    query: Object.assign({}, {...this.filter} ),
                });
            }

            try{
                // if(!this.filter.searchKey) this.filter.searchKey = 'subject';
                let { summary, successes } = await api.v1.success.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                })
                
                this.successes = successes;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        "$route.query.code"(){
            this.filter.code = this.$route.query.code;
            this.search();
        }
    }
}
</script>

<style lang="scss" scoped>
.story-item{
    &__stemp{
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url(/images/sub/story/story-stemp.svg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-item{
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: .3s ease-out;
        }
        &__hover{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgba(0, 0, 0, .5);   
            opacity: 0;
            visibility: hidden;         
            transition: .3s ease-out;
            >*{   
                opacity: 0;
                visibility: hidden;         
                transform: scale(1.4);
                transition: .3s ease-out;
            }
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 5px solid var(--v-primary-base);
            top: 0;
            left: 0;
            transform: scaleY(0);
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
        }
        &:hover{
            .story-item__contents{
                opacity: 0;
                visibility: hidden;
            }
            .story-item__hover{
                opacity: 1;
                visibility: visible;
                >*{   
                    opacity: 1;
                    visibility: visible;         
                    transform: scale(1);
                    &:nth-child(1){
                        transition: .5s .1s ease-out;
                    }
                    &:nth-child(2){
                        transition: .5s .2s ease-out;
                    }
                    &:nth-child(3){
                        transition: .5s .3s ease-out;
                    }
                }
            }
            &::after{
                opacity: 1;
                visibility: visible;
                transform: scaleY(1);
                transition: .6s .3s ease-out;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>