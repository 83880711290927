var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-table-line', _vm._b({
    staticClass: "v-data-table--density-compact",
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
      return {
        key: scopedSlotName,
        fn: function (slotData) {
          return [_vm._t(scopedSlotName, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'u-table-line', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }