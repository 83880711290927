import { render, staticRenderFns } from "./icon-double-quotes.vue?vue&type=template&id=04b4efcb&scoped=true&"
import script from "./icon-double-quotes.vue?vue&type=script&lang=js&"
export * from "./icon-double-quotes.vue?vue&type=script&lang=js&"
import style0 from "./icon-double-quotes.vue?vue&type=style&index=0&id=04b4efcb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b4efcb",
  null
  
)

export default component.exports