<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="구성원 소개" tabActive="자문단 소개">
                <template #titHead>A</template>
                <template #tit>dvisory committee</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <v-row data-aos="fade-up" class="row--lg">
                <template>
                    <v-col cols="6" md="4" v-for="(lawyer, index) in lawyers" :key="index">
                        <member-item :to="`/member/${lawyer._id}`" :image="`${lawyer.image}`">
                            <template #txt>{{lawyer.job}}</template>
                            {{lawyer.name}}
                        </member-item>
                    </v-col>
                </template>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import api from "@/api";
import { MEMBER_TYPES } from '@/assets/variables';
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import MemberItem from "@/components/client/member/member-item.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,

        MemberItem,
    },
    data() {
        return {
            lawyers: [],
            filter:{
                type: MEMBER_TYPES.ADVISORY.value
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            this.$router.push({
                query: Object.assign({}, this.query ),
            });

            try{
                let { summary, lawyers } = await api.v1.lawyers.gets({
                    params: this.filter
                })
                
                this.lawyers = lawyers;

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

    },
};
</script>
