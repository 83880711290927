var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    staticClass: "mb-40px mb-md-80px",
    attrs: {
      "data-aos": "fade-up"
    },
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": function () {
        _vm.page = 1;
        _vm.search();
      }
    }
  }), _c('v-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, _vm._l(_vm.insights, function (insight, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('router-link', {
      staticClass: "media-item plus-btn-card",
      attrs: {
        "to": `/insight_media/insight/${insight._id}`
      }
    }, [_c('v-responsive', {
      staticClass: "media-item__thumb image-card w-100",
      style: 'background-image: url(' + insight.thumb + ')',
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }), _c('div', {
      staticClass: "pt-16px pt-md-36px pb-10px pb-md-20px"
    }, [_c('u-txt-default', {
      staticClass: "txt--light line-height-14 pb-8px pb-md-16px"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(insight.label) + " ")]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(insight.createdAt).format("YYYY-MM-DD")) + " ")])], 1)], 1), _c('u-tit-default', {
      staticClass: "tit--sm line-height-15 font-weight-medium text-truncate-2"
    }, [_vm._v(" " + _vm._s(insight.subject) + " ")]), _c('icon-plus', {
      staticClass: "grey--text mt-24px mt-md-48px",
      attrs: {
        "size": "small"
      }
    })], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }