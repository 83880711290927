var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    staticClass: "mb-40px mb-md-80px",
    attrs: {
      "data-aos": "fade-up"
    },
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": function () {
        _vm.page = 1;
        _vm.search(true);
      }
    }
  }), _c('v-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, _vm._l(_vm.successes, function (success, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('story-item', {
      attrs: {
        "to": `/story/${success._id}`,
        "thumb": success.thumb
      },
      scopedSlots: _vm._u([{
        key: "date",
        fn: function () {
          return [_vm._v(_vm._s(_vm.$dayjs(success.createdAt).format("YYYY-MM-DD")))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(success.subject) + " ")])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }