var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', _vm._b({
    staticClass: "lawyer-item"
  }, 'router-link', _vm.$attrs, false), [_c('hover-line', [_c('v-img', {
    staticClass: "w-100 grey lighten-4",
    attrs: {
      "src": _vm.image,
      "aspect-ratio": 1 / 1
    }
  }), _c('div', {
    staticClass: "py-16px py-md-24px text-center"
  }, [_c('u-txt-default', {
    staticClass: "txt--sm grey--text text--darken-3 line-height-1"
  }, [_vm._t("txt")], 2), _c('div', {
    staticClass: "lawyer-item__tit pt-8px pt-md-12px mt-8px mt-md-12px"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm grey--text text--darken-3 line-height-1"
  }, [_vm._t("default")], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }