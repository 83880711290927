var render = function render(){
  var _vm$insight$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.insight ? _c('div', [_c('div', {
    staticClass: "board-view"
  }, [_c('div', {
    staticClass: "board-view__row board-view__row--title"
  }, [_vm._v(" " + _vm._s(_vm.insight.subject) + " ")]), _c('div', {
    staticClass: "board-view__row board-view__row--info"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 작성자 : " + _vm._s((_vm$insight$writer = _vm.insight.writer) === null || _vm$insight$writer === void 0 ? void 0 : _vm$insight$writer.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 작성일 : " + _vm._s(_vm.$dayjs(_vm.insight.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 조회수 : " + _vm._s(_vm.insight.viewCount) + " ")])], 1)], 1), _c('div', {
    staticClass: "board-view__row board-view__row"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.insight.content.replace(/\n/g, '<br>'))
    }
  })])]), _c('div', {
    staticClass: "mt-24px"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary3', {
    staticClass: "font-weight-medium",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }