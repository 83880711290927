var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-double-quotes",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 22"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M23.3471 0.593749C23.5558 0.593749 23.7455 0.670322 23.8783 0.823467C24.0301 0.976612 24.106 1.16804 24.106 1.37862L24.106 4.86267C24.106 5.28381 23.7455 5.62839 23.3281 5.62839C20.5961 5.62839 18.3194 7.6767 17.9399 10.3376L23.3471 10.3376C23.7645 10.3376 24.106 10.6822 24.106 11.1033L24.106 20.828C24.106 21.2492 23.7645 21.5937 23.3471 21.5937L13.7091 21.5937C13.2917 21.5937 12.9502 21.2492 12.9502 20.828L12.9502 11.1033C12.9502 5.3221 17.6174 0.612892 23.3471 0.612893L23.3471 0.593749ZM22.5882 4.13523L22.5882 2.18263C18.0538 2.56549 14.468 6.4324 14.468 11.1225L14.468 20.0623L22.5882 20.0623L22.5882 11.869L17.1431 11.869C16.7257 11.869 16.3842 11.5245 16.3842 11.1033C16.3842 7.48527 19.0973 4.51809 22.5882 4.13523Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.5219 0.593749C10.7306 0.593749 10.9203 0.670322 11.0531 0.823467C11.2049 0.976612 11.2808 1.16804 11.2808 1.35948L11.2808 4.84352C11.2808 5.26467 10.9203 5.60925 10.5029 5.60925C7.77088 5.60925 5.4942 7.65756 5.11475 10.3185L10.5029 10.3185C10.9203 10.3185 11.2618 10.663 11.2618 11.0842L11.2618 20.828C11.2618 21.2492 10.9203 21.5937 10.5029 21.5937L0.864946 21.5937C0.447554 21.5937 0.106053 21.2492 0.106053 20.828L0.106054 11.1033C0.106054 5.3221 4.77325 0.612892 10.5029 0.612893L10.5219 0.593749ZM9.744 4.13523L9.744 2.18263C5.20961 2.56549 1.62384 6.4324 1.62384 11.1225L1.62384 20.0623L9.744 20.0623L9.744 11.869L4.29894 11.869C3.88155 11.869 3.54005 11.5245 3.54005 11.1033C3.54005 7.48527 6.25309 4.51809 9.744 4.13523Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }