import { render, staticRenderFns } from "./card-quarternary.vue?vue&type=template&id=3d0a4bf1&scoped=true&"
import script from "./card-quarternary.vue?vue&type=script&lang=js&"
export * from "./card-quarternary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0a4bf1",
  null
  
)

export default component.exports