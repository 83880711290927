var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isReady ? _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('div', {
          staticClass: "lawyer-head"
        }, [_c('v-container', [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-img', {
          staticClass: "w-100 mx-auto",
          attrs: {
            "data-aos": "fade-up",
            "src": `${_vm.lawyer.image}`,
            "max-width": "588",
            "aspect-ratio": 1 / 1
          }
        })], 1), _c('v-col', {
          staticClass: "mt-n20px mt-md-0",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "lawyer-head__info"
        }, [_c('div', {
          staticClass: "pa-20px pa-md px-lg-60px py-lg-48px"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('u-tit-default', {
          staticClass: "tit--lg font-size-lg-56 line-height-14 white--text",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200",
            "data-aos-offset": "0"
          }
        }, [_vm._v(" " + _vm._s(_vm.lawyer.name) + " ")])], 1), _c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-divider', {
          staticClass: "grey lighten-3 w-md-60px w-lg-80px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "400",
            "data-aos-offset": "0"
          }
        })], 1), _c('v-col', {
          staticClass: "mt-n10px mt-md-0",
          attrs: {
            "cols": "12",
            "md": ""
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--xxl line-height-14 white--text",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "600",
            "data-aos-offset": "0"
          }
        }, [_vm._v(" " + _vm._s(_vm.lawyer.job) + " ")])], 1)], 1)], 1)])])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 126087949)
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('u-tit-default', {
    staticClass: "font-size-md-28 font-weight-medium line-height-1",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 약력 ")]), _c('v-divider', {
    staticClass: "primary lighten-3 border-1 border-md-3 mt-16px mt-md-24px"
  }), _c('card-secondary', {
    staticClass: "mt-24px mt-md-48px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-32px"
  }, _vm._l(_vm.lawyer.career, function (career, index) {
    return _c('txt-dot', {
      key: index,
      staticClass: "txt--xl mt-8px mt-md-12px",
      attrs: {
        "dotNone": !career.txt
      }
    }, [_vm._v(" " + _vm._s(career.txt) + " ")]);
  }), 1)])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }