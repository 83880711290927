<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="심리안정프로그램">
                <template #titHead>P</template>
                <template #tit>rogram</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first">
            <div class="image-card pa-20px pa-md-30px px-lg-0 py-lg-88px" style="background-image: url(/images/sub/program/program-bg.jpg)">
                <v-row align="center">
                    <v-col cols="12" md="6" lg="">
                        <div class="pl-lg-48px">
                            <u-tit-default data-aos="fade-up" class="line-height-15">
                                리브이혼센터는 오롯이 의뢰인의 행복한 삶을 위한
                                종합 법률서비스를 제공하고 있습니다.
                            </u-tit-default>
                        </div>
                    </v-col>
                    <v-col cols="6" lg="auto" offset="6" offset-md="0">
                        <div class="w-100 pr-lg-48px">
                            <v-img data-aos="zoom-out" src="/images/sub/program/program-bg2.svg" max-width="470" :width="$vuetify.breakpoint.lgAndUp ? 470 : ''" :aspect-ratio="470 / 324" class="w-100" />
                        </div>
                    </v-col>
                </v-row>
            </div>
            <u-txt-default class="line-height-17 pt-12px pt-md-24px">
                <v-row class="row--xl">
                    <v-col cols="12" md="6">
                        <p data-aos="fade-up">
                            판심이 수많은 이혼소송을 경험하면서 느낀 결론은, 의뢰인님의 행복을 위해서는 법률적
                            조력도 필요하지만, <span class="grey--text text--darken-4">이혼을 결심하시기까지 받은 정신적 고통을 치유하고, 자녀들이 상처받지
                            않도록 정서적으로 보호하는 일 또한 매우 중요하다는 것입니다.</span>
                        </p>
                        <p data-aos="fade-up" class="mt-12px mt-md-24px">
                            따라서 리브이혼센터는 의뢰인님과 자녀들이 <span class="grey--text text--darken-4">이혼소송 전에 받았던 정서적 상처의 치유</span>와
                            <span class="grey--text text--darken-4">이혼소송 중에 발생할 수 있는 정신적 고통의 예방</span>을 위하여 심리안정 프로그램을 마련하고 있습니다.
                        </p>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-n8px mt-md-0">
                        <p data-aos="fade-up">
                            리브이혼센터는 '부모와 아이 마음 의원', '유앤문 정신건강의학과'와의 제휴를 통해 의뢰인과 
                            그 자녀들을 위한 심리안정 프로그램을 운영하여 이혼소송 중에서도 정서적으로 안정된 삶을
                            살아가실 수 있도록 지원해드립니다.
                        </p>
                        <u-tit-default data-aos="fade-up" class="tit--sm line-height-15 grey--text text--darken-4 mt-12px mt-md-24px">
                            리브이혼센터는 오롯이 의뢰인의 행복한 삶을 위한 <br class="d-none d-xl-block" />
                            종합 법률서비스를 제공하고 있습니다.
                        </u-tit-default>
                    </v-col>
                </v-row>
            </u-txt-default>
        </page-section-primary>

        <page-section-primary class="pt-0 page-section--last">
            <div v-for="(item, index) in info" :key="index" :class="index !== 0 ? 'pt-40px pt-md-60px pt-lg-100px' : ''">
                <v-row align="center" class="image-row" :class="index % 2 == 0 ? 'image-row--reverse' : ''">
                    <v-col cols="12" md="5">
                        <div class="image-row__image">
                            <div class="image-row__image__inner">
                                <div data-aos="zoom-out" class="image-row__image__bg"></div>
                                <v-img data-aos="zoom-out" data-aos-delay="200" :src="item.image" :aspect-ratio="1 / 1" class="w-100 elevation-16"/>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7" class="mt-20px mt-md-0">
                        <tit-wrap-secondary>
                            {{item.title}}
                            <template #TxtAdd>{{item.txt}}</template>
                        </tit-wrap-secondary>
                        <v-row data-aos="fade-up" data-aos-delay="200" class="row--xs mx-md-n20px">
                            <v-col v-for="(child, childIndex) in item.infoDetail" :key="childIndex" cols="12" md="6" xl="auto" class="px-md-20px" :class="childIndex !== 0 ? 'mt-2px mt-md-0' : ''">
                                <txt-dot v-for="(detail, detailIndex) in child" :key="detailIndex" :class="detailIndex !== 0 ? 'mt-4px mt-md-8px' : ''">
                                    <span v-html="detail"></span>
                                </txt-dot>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitWrapSecondary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TxtDot,
    },
    data() {
        return {
            info: [
                {
                    title: "문현진",
                    txt: "대표원장",
                    infoDetail: [
                        [
                            "정신건강의학과 전문의",
                            "가톨릭대학교 의과대학졸업",
                            "가톨릭대학교 의과대학 대학원 석,박사졸업",
                            "가톨릭중앙의료원 정신과 레지던트 수련",
                            "여의도 성모병원 임상강사",
                            "서울성모병원 정신건강의학과 외래교수",
                            "대한소아청소년정신의학회 정회원",
                        ],
                        [
                            "대한신경정신의학회 정회원",
                            "대한우울조울병학회 정회원",
                            "대한불만의학회 정회원",
                            "한국인지행동치료 정회원",
                            "대한정신약물학회 정회원",
                            "대한비만학회 정회원",
                            "판심법무법인 협업 정신과전문의",
                        ],
                    ],
                    image: "/images/sub/program/program-img.jpg",
                },
                {
                    title: "문수진",
                    txt: "대표원장",
                    infoDetail: [
                        [
                            "소아청소년정신과 전문의",
                            "의학박사/정신건강의학과 전문의<br class='d-none d-xl-block' />/경희대학교 의과대학 졸업",
                            "한국정신분석학회 심층정신치료 고급훈련과정 졸업",
                            "전 경희대학교병원 소아청소년<br class='d-none d-xl-block' /> 정신건강의학과 임상강사",
                            "전 서울특별시 중부교육진원청 및<br class='d-none d-xl-block' /> 동대문구정신보건센터 소아청소년 특별상담의",
                            "전 대학소아청소년정신의학회 총무위원 및 홍보위원",
                        ],
                        [
                            "전 대동병원 소아청소년 병원학교장(나래학교)<br class='d-none d-xl-block' />/대동 wee센터장",
                            "우수연구 논문상, 경희대학교 의학전문대학원",
                            "중앙문화의학상, 대한신경정신의학회",
                            "대한신경정신의학회 정회원",
                            "정신신체전문가 인증의",
                            "한국정신분석학회 정회원",
                            "대한소아청소년정신의학회 정회원",
                            "국제정신분석학회 IPA 정신분석가",
                        ],
                    ], 
                    image: "/images/sub/program/program-img2.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-row{
    --image-padding: 20px;
    &__image{
        &__inner{
            max-width: 432px;
            position: relative;
            padding: var(--image-padding) var(--image-padding) 0 0;
        }
        &__bg{
            position: absolute;
            width: calc(100% - var(--image-padding));
            height: calc(100% - var(--image-padding));
            right: 0;
            top: 0;
            background-color: var(--v-primary-lighten4);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .image-row{
        --image-padding: 48px;
        &__image{
            padding-right: 54px;
        }
        &--reverse{
            flex-direction: row-reverse;
            .image-row__image{
                padding-right: 0;
                padding-left: 54px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
