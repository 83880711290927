<template>
    <div>
        <v-row>
            <v-col cols="12" md="4">
                <select-primary value="subject" :items="searchKeys" class="v-input--density-default"  @input="searchKey"/>
            </v-col>
            <v-col cols="12" md="8" class="mt-n12px mt-md-0">
                <text-field-primary placeholder="검색어를 입력하세요." class="v-input--density-default"  @input="searchValue" @search="$emit('search')">                
                    <template #append> 
                        <v-btn large icon color="grey darken-4" class="v-btn--none-active-bg ma-n10px" @click="$emit('search')"><icon-search /></v-btn>
                    </template>
                </text-field-primary>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import IconSearch from "@/components/publish/styles/icons/icon-search.vue";

export default {
    components: {
        TextFieldPrimary,
        SelectPrimary,
        IconSearch,
    },

    data() {
        return {
            searchKeys: [
                // { text: "전체", value: null },
                { text: "제목", value: "subject" },
                { text: "제목 + 내용", value: "subjectcontent" },
            ],
        }
    },
    methods: {
        searchKey(searchKey) {
            this.$emit("searchKey", searchKey);
        },
        searchValue(searchValue) {
            this.$emit("searchValue", searchValue);
        },
    },
    computed:{
        category(){
            return this.$route.path.split("/").pop();
        }
    }
}
</script>

<style>

</style>