var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "리브이혼센터",
            "tabActive": "리브이혼센터 소개"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("R")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("e : V")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--about page-section--first page-section--last"
  }, _vm._l(_vm.info, function (item, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'pt-40px pt-md-24px' : ''
    }, [_c('v-row', {
      staticClass: "about-row",
      attrs: {
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "about-row__tit",
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-120px w-md-200px h-120px h-md-200px rounded-circle border",
      staticStyle: {
        "border-color": "var(--v-primary-base) !important"
      },
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "400"
      }
    }, [_c('div', {
      staticClass: "w-100 h-100 d-flex align-center justify-center text-center"
    }, [_c('u-tit-default', {
      staticClass: "primary--text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('card-secondary', [_c('div', {
      staticClass: "pa-16px pa-md-32px"
    }, [_c('u-txt-default', [_vm._l(item.txt, function (detail, index) {
      return _c('p', {
        key: index,
        staticClass: "line-height-17 mb-8px mb-md-16px",
        class: index !== 0 ? 'mt-8px mt-md-16px' : '',
        attrs: {
          "data-aos": "fade-up"
        },
        domProps: {
          "innerHTML": _vm._s(detail)
        }
      });
    }), _c('u-tit-default', {
      staticClass: "tit--sm font-primary line-height-15",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_vm._v(" " + _vm._s(item.txtAdd) + " ")])], 2)], 1)])], 1)], 1)], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }