<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-svg icon-forward" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M19.5 12C19.5 12.1918 19.4267 12.3838 19.2803 12.5302L11.7803 20.0302C11.4872 20.3233 11.0127 20.3233 10.7198 20.0302C10.4269 19.7371 10.4267 19.2626 10.7198 18.9697L17.6895 12L10.7198 5.0302C10.4267 4.73714 10.4267 4.26258 10.7198 3.9697C11.0129 3.67683 11.4874 3.67664 11.7803 3.9697L19.2803 11.4697C19.4267 11.6161 19.5 11.8081 19.5 12ZM13.2803 11.4697L5.7803 3.9697C5.48723 3.67664 5.01267 3.67664 4.7198 3.9697C4.42692 4.26276 4.42673 4.73733 4.7198 5.0302L11.6895 12L4.7198 18.9697C4.42673 19.2628 4.42673 19.7373 4.7198 20.0302C5.01286 20.3231 5.48742 20.3233 5.7803 20.0302L13.2803 12.5302C13.4267 12.3838 13.5 12.1918 13.5 12C13.5 11.8081 13.4267 11.6161 13.2803 11.4697Z"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 24px;
    }
}
</style>
