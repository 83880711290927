import { render, staticRenderFns } from "./u-swiper-pagination-ellipse.vue?vue&type=template&id=ead9a002&scoped=true&"
import script from "./u-swiper-pagination-ellipse.vue?vue&type=script&lang=js&"
export * from "./u-swiper-pagination-ellipse.vue?vue&type=script&lang=js&"
import style0 from "./u-swiper-pagination-ellipse.vue?vue&type=style&index=0&id=ead9a002&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ead9a002",
  null
  
)

export default component.exports