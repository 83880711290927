import { render, staticRenderFns } from "./txt-secondary.vue?vue&type=template&id=0881a762&scoped=true&"
import script from "./txt-secondary.vue?vue&type=script&lang=js&"
export * from "./txt-secondary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0881a762",
  null
  
)

export default component.exports