<template>
    <app-primary  v-if="isReady">
        <template #contentsHead>
            <div class="lawyer-head">
                <v-container>
                    <v-row align="end">
                        <v-col cols="12" md="6">
                            <v-img data-aos="fade-up" :src="`${lawyer.image}`" max-width="588" :aspect-ratio="1 / 1" class="w-100 mx-auto" />
                        </v-col>
                        <v-col cols="12" md="6" class="mt-n20px mt-md-0">
                            <div class="lawyer-head__info">
                                <div class="pa-20px pa-md px-lg-60px py-lg-48px">
                                    <v-row align="center">
                                        <v-col cols="auto">
                                            <u-tit-default data-aos="fade-up" data-aos-delay="200" data-aos-offset="0" class="tit--lg font-size-lg-56 line-height-14 white--text">
                                                {{lawyer.name}}
                                            </u-tit-default>
                                        </v-col>
                                        <v-col md="auto">
                                            <v-divider data-aos="fade-up" data-aos-delay="400" data-aos-offset="0" class="grey lighten-3 w-md-60px w-lg-80px" />
                                        </v-col>
                                        <v-col cols="12" md="" class="mt-n10px mt-md-0">
                                            <u-txt-default data-aos="fade-up" data-aos-delay="600" data-aos-offset="0" class="txt--xxl line-height-14 white--text">
                                                {{lawyer.job}}
                                            </u-txt-default>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <u-tit-default data-aos="fade-up" class="font-size-md-28 font-weight-medium line-height-1">
                약력
            </u-tit-default>
            <v-divider class="primary lighten-3 border-1 border-md-3 mt-16px mt-md-24px" />
            <card-secondary data-aos="fade-up" data-aos-delay="200" class="mt-24px mt-md-48px">
                <div class="pa-16px pa-md-32px">
                    <txt-dot v-for="(career, index) in lawyer.career" :key="index" :dotNone="!career.txt" class="txt--xl mt-8px mt-md-12px">
                        {{ career.txt }}
                    </txt-dot>
                </div>
            </card-secondary>
        </page-section-primary>

    </app-primary>
</template>

<script>
import api from "@/api";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";

export default {
    props: {
    },
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
        TxtDot,
    },
    data() {
        return {
            lawyer: null,
            isReady: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { lawyer } = await api.v1.lawyers.get({ _id: this.$route.params._lawyer })
                
                this.lawyer = lawyer;
                this.isReady = true;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.lawyer-head{
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: var(--contents-padding-top);
    background-image: url(/images/sub/lawyer/lawyer-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__info{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: rgba(23, 28, 33, .8);
        }
        >*{
            position: relative;
            z-index: 1;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer-head{
        &__info{
            &::before{
                left: 0;
                transform: translateX(0);
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
