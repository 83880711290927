<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 16"
        class="icon-svg icon-arrow-rounded"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M12.75,6.77c-.33,.32-.87,.32-1.2,0L7.36,2.77V15.19c0,.45-.38,.81-.85,.81h-.02c-.47,0-.85-.36-.85-.81V2.77L1.45,6.77c-.33,.32-.87,.32-1.2,0-.33-.32-.33-.83,0-1.14L5.9,.24c.33-.32,.87-.32,1.2,0l5.65,5.39c.33,.32,.33,.83,0,1.14Z"
        />
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 13"
        class="icon-svg icon-arrow-rounded"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M6.77082 0.248392C7.08667 0.579581 7.08667 1.11617 6.77082 1.44736L2.76879 5.64376H15.1916C15.6382 5.64376 16 6.02346 16 6.49147V6.50853C16 6.97688 15.6379 7.35624 15.1916 7.35624H2.76879L6.77082 11.5526C7.08667 11.8838 7.08667 12.4204 6.77082 12.7516C6.45497 13.0828 5.94323 13.0828 5.62738 12.7516L0.236886 7.09932C-0.0789623 6.76813 -0.0789623 6.23154 0.236886 5.90035L5.62738 0.248392C5.94323 -0.0827972 6.45497 -0.0827972 6.77082 0.248392Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
